import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    console.log("HERE")
  }

  disconnect() {
    console.log("BYE")
  }
}
